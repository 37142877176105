import {EventDispatcher} from './EventDispatcher';
import router from "@/router";

export default class WorkspaceData extends EventDispatcher {

  constructor() {
    super();
    this.workspace = {
      // aktueller random seed, long, wird beim erzeugen des Beispieltextes mitgeschickt
      seed: 16516521651,
      // Beispieltext kann ebenfalls im Workspace gespeichert werden, muss er aber nicht (könnt ihr selber entscheiden)
      // Speichern im Workspace hätte den Vorteil, dass man bei der Feedbackfunktion einfach den ganzen Workspace losschicken kann.
      example_text_without_replacement: "",
      example_text_with_replacement: "",

      // die signal baumstruktur, wie von Dir zuletzt für das Beispiel definiert (finde ich gerade nicht, kannst Du noch mal schauen)
      // welche es gibt, aktiv/inaktiv etc. kommt immer vom server, zusammen mit beispieltext
      signals: [
      ],
      selected_layout: 1,
      selected_section_alternatives: {
        1: 1,
      },

      // Die gemäß der aktuellen isin & datumsauswahl und der daraus resultierenden signale aktiven (dunkelgrau dargestellten)
      // layouts. Liste der Ids, die erste Id bekommt das blaue Fähnchen.
      active_layouts: [3, 4],

      // Analog zu den aktiven layouts die aktiven Alternativen innerhalb der sections
      active_section_alternatives: {
        1: [1, 8], //ids der aktiven section alternatives, erste id bekommt das fähnchen
        4: [22], //auch wenn keine Alternative aktiv ist, enthält die map einen Eintrag
      },

      active_synonym_replacements: {
        1: [1],
      },

      //Arbitrary JSON object with signal data. Some customers can define their own signals values,
      // they are simply the members of this object. These values are sent when rendering a template
      // E.g. { 'foo':5, 'bar': {'baz': 'someString'}} => foo=5, bar=true, bar.baz='someString'
      dynamic_signals: {},

      // das aktuelle template, es gibt immer nur eins
      template: {
        name: "Neue Story",
        next_layout_id: 2,
        next_section_id: 2,
        next_section_alternative_id: 2,
        next_synonym_id: 2,
        next_synonym_replacement_id: 2,
        next_text_alternative_id: 2,
        settings: {
          selectors: [],
          priority: 50,
          weight: 50,
        },
        layouts: [
          {
            id: 1,
            name: "Layout",
            selectors: [],
            section_ids: [1],
          },
        ],
        sections: {
          1: {
            id: 1,
            name: "Abschnitt",

            section_alternatives: [
              {
                id: 1,
                name: 'Alternative',
                selectors: [],
                text_alternatives: [{id: 1, text:'<p>Text</p>'}],
              },
            ]
          },
        },
        synonyms: [
          {
            id: 1,
            key: "schnell",
            replacements: [
              {
                id: 1,
                selectors: [],
                value: "schnell; zügig;"
              },
            ]
          },
        ]
      }
    }
    this.customerId = '';
  }

  freshWorkspace() {
    this.workspace = {};
    this.workspace.seed = Date.now();
    this.workspace.example_text_without_replacement = '';
    this.workspace.example_text_with_replacement = '';
    this.workspace.signals = [];
    this.workspace.selected_layout = null;
    this.workspace.selected_section_alternatives = {};
    this.workspace.active_layouts = [];
    this.workspace.active_section_alternatives = {};
    this.workspace.active_synonym_replacements = {};
    this.workspace.dynamic_signals = {};
    this.workspace.template = {};

    /* for Demo Customer */
    this.workspace.animals = [];

    /* for Onvista Customer */
    this.workspace.stocks = [];
    this.workspace.dates = [];

    this.getInitalSignals();
  }

  async getInitalSignals() {

    const response = await fetch(`/api/v1/templates/signals?customerCode=${this.customerId}`);
    let responseJson = null;

    switch (response.status) {
      case 200:
        responseJson = await response.json();
        this.workspace = {
          ...this.workspace,
          ...responseJson,
        }
        break;
      case 401:
        await router.push({name:'Login'});
        break;
      default:
        await router.push({name:'Login'});
        this.dispatchEvent( {
          type: 'toast-msg',
          message: {
            type: 'error',
            text: `(${response.status}): ${response.statusText}`,
          },
        });
    }
  }

  async getTemplates() {
    const response = await fetch(`/api/v1/templates/?customerCode=${this.customerId}`);

    switch (response.status) {
      case 200:
        return await response.json();
      case 401:
        await router.push({name:'Login'});
        break;
      default:
        this.dispatchEvent( {
          type: 'toast-msg',
          message: {
            type: 'error',
            text: `(${response.status}): ${response.statusText}`,
          },
        });
    }
  }

  async getTemplate(templateId) {
    const response = await fetch(`/api/v1/templates/${templateId}?customerCode=${this.customerId}`);

    switch (response.status) {
      case 200:
        return await response.json();
      case 401:
        await router.push({name:'Login'});
        break;
      default:
        this.dispatchEvent( {
          type: 'toast-msg',
          message: {
            type: 'error',
            text: `(${response.status}): ${response.statusText}`,
          },
        });
    }
  }

  getData() {
    return this.workspace;
  }

  getDataAsJSON() {
    return JSON.stringify(this.workspace);
  }

  getSignals() {
    if (this.workspace.signals.length) {
      return this.workspace.signals;
    }
  }

  getSelectedLayout() {
    return this.workspace.selected_layout;
  }

  getSelectedSection() {
    return this.workspace.selected_section_alternatives;
  }

  getSelectedSectionAlternative(sectionId) {
    return this.workspace.selected_section_alternatives[sectionId];
  }

  getActiveLayouts() {
    return this.workspace.active_layouts;
  }

  getActiveSectionAlternatives() {
    return this.workspace.active_section_alternatives;
  }

  getActiveSynonymReplacements() {
    return this.workspace.active_synonym_replacements;
  }

  getSectionsByLayoutId(layoutId) {
    const sectionIds = this.workspace.template.layouts.filter((layout) => layout.id === parseInt(layoutId))[0].section_ids;
    return sectionIds.map((id) => {
      return this.workspace.template.sections[id];
    });
  }

  getLayoutById(layoutId) {
    const id = parseInt(layoutId);
    return this.workspace.template.layouts.find(layout => layout.id === id);
  }

  getSynonymById(synonymId) {
    const id = parseInt(synonymId);
    return this.workspace.template.synonyms.find(synonym => synonym.id === id);
  }

  getSectionById(sectionId) {
    return this.workspace.template.sections[sectionId];
  }

  getSectionAlternativesById(sectionId) {
    return this.workspace.template.sections[sectionId]?.section_alternatives;
  }

  getSettings() {
    return this.workspace.template.settings;
  }

  getNextSynonymId() {
    return this.workspace.template.next_synonym_id;
  }

  getRenderAllowance() {
    return true;
  }

  getRenderQuery() {
    return false;
  }

  async renderTemplate() {
    if(this.getRenderQuery()) {
      const spinner = document.getElementById("spinner");
      spinner.removeAttribute('hidden');

      const response = await fetch(`/api/v1/templates/render?${this.getRenderQuery()}`,
          {
            method: 'POST',
            headers: {
              'accept': '*/*',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              "dynamic_signals": this.workspace.dynamic_signals,
              "layout_template": this.workspace.template
            })
          })
      const responseJson = await response.json();

      switch (response.status) {
        case 200:
          this.workspace = {...this.workspace, ...responseJson}
          break;
        case 401:
          await router.push({name:'Login'});
          break;
        case 404:
          this.onRenderResponse404();
          break;
        default:
          this.dispatchEvent( {
            type: 'toast-msg',
            message: {
              type: 'error',
              text: `(${response.status}): ${response.statusText}`,
            },
          });
      }
      spinner.setAttribute('hidden', '');
    } else {
      console.error('No render query');
    }
  }

  onRenderResponse404() {
    this.dispatchEvent( {
      type: 'toast-msg',
      message: {
        type: 'warning',
        text: `Wir können leider keine Daten finden`,
      },
    });
  }

  saveSettings(priority, weight) {
    this.workspace.template.settings.priority = priority;
    this.workspace.template.settings.weight = weight;
  }

  async submitFeedback(formData) {
    formData.append('json', JSON.stringify(this.workspace));
    const response = await fetch('/api/v1/account/feedback', {
      method: 'POST',
      body: formData
    })

    switch (response.status) {
      case 200:
        return response.json();
      case 401:
        await router.push({name:'Login'});
        break;
      default:
        this.dispatchEvent( {
          type: 'toast-msg',
          message: {
            type: 'error',
            text: `(${response.status}): ${response.statusText}`,
          },
        });
    }
  }

  async askThesaurus(key) {
    const response = await fetch(`/api/v1/templates/thesaurus?synonymKey=${key}`);
    const responseJson = await response.json();

    switch (response.status) {
      case 200:
        return responseJson.synonym_value_suggestions;
      case 401:
        await router.push({name:'Login'});
        break;
      default:
        this.dispatchEvent( {
          type: 'toast-msg',
          message: {
            type: 'error',
            text: `(${response.status}): ${response.statusText}`,
          },
        });
    }
  }

  changeSeed() {
    this.workspace.seed = Date.now();
    this.renderTemplate();
  }

  newTemplate() {
    this.freshWorkspace();
    const templateObject = {};
    templateObject.name = 'Neue Story';
    templateObject.next_layout_id = 2;
    templateObject.next_section_id = 2;
    templateObject.next_section_alternative_id = 2;
    templateObject.next_synonym_id = 2;
    templateObject.next_synonym_replacement_id = 2;
    templateObject.next_text_alternative_id = 2;
    templateObject.settings = {};
    templateObject.settings.selectors = [];
    templateObject.settings.priority = 50;
    templateObject.settings.weight = 50;
    templateObject.synonyms = [
      {
        id: 1,
        key: "schnell",
        replacements: [
          {
            id: 1,
            selectors: [],
            value: "schnell; zügig;"
          },
        ]
      },
    ];
    templateObject.sections = {
      1: {
        id: 1,
        name: "Abschnitt",
        section_alternatives: [
          {
            id: 1,
            name: 'Alternative',
            selectors: [],
            text_alternatives: [{ id: 1, text: '<p>Text</p>', }],
          },
        ]
      },
    };
    templateObject.layouts = [
      {
        id: 1,
        name: "Layout",
        selectors: [],
        section_ids: [1],
      },
    ];
    this.workspace.template = templateObject;
  }

  openTemplate(data) {
    /* Workspace Files */
    if (data.template) {
      this.workspace = {
        ...this.workspace,
        ...data,
        ...{
          template: {
            ...data.template,
            settings: this.legacyToSettings(data.template.settings),
            sections: this.mapOldTextAlternatives(data.template.sections),
            next_text_alternative_id: data.template.next_text_alternative_id || this.workspace.template.next_text_alternative_id,
          }
        }
      };
      this.renderTemplate();
    /* Template Files */
    } else {
      this.freshWorkspace()
      this.workspace.template = {
        ...data,
        next_layout_id: this.legacyToNextLayout(data),
        next_section_id: this.legacyToNextSection(data),
        next_synonym_id: this.legacyToNextSynonym(data),
        next_section_alternative_id: this.legacyToNextSectionAlternative(data),
        next_synonym_replacement_id: this.legacyToNextSynonymReplacement(data),
        next_text_alternative_id: this.legacyToNextTextAlternative(data),
      };
    }
  }

  /* This is only needed as long as there are "old" workspace files floating about */
  legacyToSettings(settingsObject) {
    return {
      ...this.workspace.template.settings,
      ...settingsObject
    }
  }

  /* This is only needed as long as there are "old" template files floating about */
  legacyToNextLayout(data) {
    if (data.next_layout_id) {
      return data.next_layout_id;
    } else {
      return Math.max(...data.layouts.map(o => o.id), 0) + 1;
    }
  }

  /* This is only needed as long as there are "old" template files floating about */
  legacyToNextSynonym(data) {
    if (data.next_synonym_id) {
      return data.next_synonym_id;
    } else {
      return Math.max(...data.synonyms.map(o => o.id), 0) + 1;
    }
  }

  /* This is only needed as long as there are "old" template files floating about */
  legacyToNextSection(data) {
    if (data.next_section_id) {
      return data.next_section_id;
    } else {
      let maxId = Object.keys(data.sections).reduce((a, b) => data.sections[a] > data.sections[b] ? a : b);
      return parseInt(maxId) + 1;
    }
  }

  /* This is only needed as long as there are "old" template files floating about */
  legacyToNextSynonymReplacement(data) {
    if (data.next_synonym_replacement_id) {
      return data.next_synonym_replacement_id;
    } else {
      let synonymReplacementIds = [];
      data.synonyms.forEach(synonym => {
        let id = Math.max(...synonym.replacements.map(o => o.id), 0)
        synonymReplacementIds.push(id)
      })
      return Math.max(...synonymReplacementIds) + 1;
    }
  }

  /* This is only needed as long as there are "old" template files floating about */
  legacyToNextSectionAlternative(data) {
    if (data.next_section_alternative_id) {
      return data.next_section_alternative_id;
    } else {
      let sectionAlternativeIds = [];
      Object.entries(data.sections).forEach(section => {
        let id = Math.max(...section[1].section_alternatives.map(o => o.id), 0);
        sectionAlternativeIds.push(id);
      })
      return Math.max(...sectionAlternativeIds) + 1;
    }
  }

  /* This is only needed as long as there are "old" template files floating about */
  legacyToNextTextAlternative(data) {
    if (data.next_text_alternative_id) {
      return data.next_text_alternative_id;
    } else {
      let textAlternativeIds = [];
      Object.entries(data.sections).forEach(section => {
        section[1].section_alternatives.forEach( sectionAlternative => {
          let id = Math.max(...sectionAlternative.text_alternatives.map(o => o.id), 0)
          textAlternativeIds.push(id);
        })
      })
      return Math.max(...textAlternativeIds) + 1;
    }
  }

  mapOldTextAlternatives(sectionsObject) {
    let mapedObj = {};

    if(!this.workspace.template.next_text_alternative_id) {
      this.workspace.template.next_text_alternative_id = 1;
    }
    for(const key in sectionsObject) {
      mapedObj[key] = { ...sectionsObject[key], ...{
          section_alternatives: sectionsObject[key].section_alternatives.map(sectionAlternative => {
            return { ...sectionAlternative, ...{text_alternatives: sectionAlternative.text_alternatives.map((textAlternative) => {
                  if(typeof textAlternative === 'string') {
                    return {id: this.workspace.template.next_text_alternative_id++, text: textAlternative};
                  }
                  return textAlternative;
            })}};
          }),
        }};
    }
    return mapedObj;
  }

  changeLayoutOrder(dragging, draggedOver) {
    const draggingContent = this.workspace.template.layouts[dragging];
    this.workspace.template.layouts.splice(dragging, 1);
    this.workspace.template.layouts.splice(draggedOver, 0, draggingContent);
    this.renderTemplate();
  }

  changeSectionOrder(targetLayoutId, targetSectionId, sectionId) {
    // get the targetLayout
    const targetLayout = this.getLayoutById(targetLayoutId);
    if (!targetLayout) {
      return;
    }
    // find the index of the target section id we want to insert after
    const targetSectionIdx = targetLayout.section_ids.indexOf(targetSectionId);
    if (targetSectionIdx === -1) {
      return;
    }
    // remove the section id, in case it already exists at another position
    const sectionIdx = targetLayout.section_ids.indexOf(sectionId);
    if (sectionIdx !== -1) {
      targetLayout.section_ids.splice(sectionIdx, 1);
    }
    // insert the section id after the target section id
    targetLayout.section_ids.splice(targetSectionIdx, 0, sectionId);
    this.renderTemplate();
  }

  changeSynonymReplacementOrder(targetSynonymId, dragging, draggedOver) {
    // get the targetSynonym
    let targetSynonym = this.getSynonymById(targetSynonymId);
    const draggingContent = targetSynonym.replacements[dragging];
    targetSynonym.replacements.splice(dragging, 1);
    targetSynonym.replacements.splice(draggedOver, 0, draggingContent);
    this.renderTemplate();
  }

  appendSynonym(newWord) {
    // get the next available synonymId and increment
    let nextSynonymId = this.workspace.template.next_synonym_id++;
    // get the next available synonymReplacementId and increment
    let synonymReplacementId = this.workspace.template.next_synonym_replacement_id++;
    // build the basic synonymObject
    let synonymObject = {}
    synonymObject.id = nextSynonymId;
    synonymObject.key = newWord;
    synonymObject.replacements = [
      {
        id: synonymReplacementId,
        selectors: [],
        value: `${newWord};`
      },
    ];
    this.workspace.template.synonyms.push(synonymObject);
    this.renderTemplate();
  }

  appendSynonymReplacement(targetSynonymId) {
    // get the targetSynonym
    let targetSynonym = this.getSynonymById(targetSynonymId);
    // get the next available synonymId and increment
    let nextSynonymReplacementId = this.workspace.template.next_synonym_replacement_id++;
    // build the basic synonymReplacementObject
    let synonymReplacementObject = {};
    synonymReplacementObject.id = nextSynonymReplacementId;
    synonymReplacementObject.selectors = [];
    synonymReplacementObject.value = '';
    targetSynonym.replacements.push(synonymReplacementObject);
    this.renderTemplate();
  }

   changeSynonymReplacement(targetSynonymId, targetSynonymReplacementId, text) {
    // get the targetSynonym by it's id
    let targetSynonym = this.getSynonymById(targetSynonymId);
    if (!targetSynonym) {
      return;
    }
    // get the targetSynonymReplacement by it's id
    let targetSynonymReplacement = targetSynonym.replacements.find(synonymReplacement => synonymReplacement.id === targetSynonymReplacementId);
    if (!targetSynonymReplacement) {
      return;
    }
    // replace the original value with the new value
     if (text !== targetSynonymReplacement.value) {
       targetSynonymReplacement.value = text;
       this.renderTemplate();
     }
  }

  createAndAppendNewSection(targetLayoutId) {
    // get the targetLayout
    const targetLayout = this.getLayoutById(targetLayoutId);
    if (!targetLayout) {
      return;
    }
    // get the next available sectionId and increment
    let sectionId = this.workspace.template.next_section_id++;
    // get the next available sectionAlternativeId
    let nextSectionAlternativeId = this.workspace.template.next_section_alternative_id++;
    // build the basic sectionObject
    let sectionObject = {};
    sectionObject.id = sectionId;
    sectionObject.name = 'Abschnitt ' + sectionId;
    sectionObject.section_alternatives = [
      {
        id: nextSectionAlternativeId,
        name: 'Alternative',
        selectors: [],
        text_alternatives: [{ id: 1, text: '<p>Text</p>', }],
      },
    ];
    this.workspace.template.sections[sectionId] = sectionObject;
    // add sectionId it at the end of section_ids
    targetLayout.section_ids.push(sectionId);
  }

  appendNewLayout() {
    // get the next available layoutId and increment
    let layoutId = this.workspace.template.next_layout_id++;
    // build the basic sectionObject
    let layoutObject = {};
    layoutObject.id = layoutId;
    layoutObject.name = 'Layout ' + layoutId
    layoutObject.selectors = [];
    layoutObject.section_ids = [];
    this.workspace.template.layouts.push(layoutObject);
  }

  appendSectionIdToLayout(targetLayoutId, sectionId) {
    // get the targetLayout
    const targetLayout = this.getLayoutById(targetLayoutId);
    if (!targetLayout) {
      return;
    }
    //check if the section is already used in the layout
    if (targetLayout.section_ids.includes(sectionId)) {
      // if it is already present, remove it
      let pos = targetLayout.section_ids.indexOf(sectionId);
      targetLayout.section_ids.splice(pos, 1);
    }
    // push it onto the end of the array
    targetLayout.section_ids.push(sectionId);
    this.renderTemplate();
  }

  /**
   * TEXT ALTERNATIVES --- START
   */

  appendTextAlternativeToSectionAlternative(targetSectionId, targetSectionAlternativeId) {
    // get the targetSection by it's id
    const targetSection = this.getSectionById(targetSectionId);
    if (!targetSection) {
      return
    }
    // get the targetSectionAlternative by it's id
    const targetSectionAlternative = targetSection.section_alternatives.find(sectionAlternative => sectionAlternative.id === targetSectionAlternativeId);
    if (!targetSectionAlternative) {
      return
    }
    // add an empty string to the end of the array
    targetSectionAlternative.text_alternatives.push({id: this.workspace.template.next_text_alternative_id++ , text: 'Text'});
  }

  changeTextAlternative(targetSectionId, targetSectionAlternativeId, targetTextAlternativeId, htmlText) {
    // get the targetSection by it's id
    const targetSection = this.getSectionById(targetSectionId);
    if (!targetSection) {
      return
    }
    // get the targetSectionAlternative by it's id
    const targetSectionAlternative = targetSection.section_alternatives.find(sectionAlternative => sectionAlternative.id === targetSectionAlternativeId);
    if (!targetSectionAlternative) {
      return
    }
    const targetTextAlternative = targetSectionAlternative.text_alternatives.find((alternative) => alternative.id === targetTextAlternativeId);

    if (targetTextAlternative.text !== htmlText) {
      targetTextAlternative.text = htmlText;
      this.renderTemplate();
    }
  }

  deleteTextAlternative(targetSectionId, targetSectionAlternativeId, targetTextAlternativeId) {
    // get the targetSection by it's id
    const targetSection = this.getSectionById(targetSectionId);
    if (!targetSection) {
      return
    }
    // get the targetSectionAlternative by it's id
    const targetSectionAlternative = targetSection.section_alternatives.find(sectionAlternative => sectionAlternative.id === targetSectionAlternativeId);
    if (!targetSectionAlternative) {
      return
    }

    const index = targetSectionAlternative.text_alternatives.findIndex((alternative) => alternative.id === targetTextAlternativeId);
    targetSectionAlternative.text_alternatives.splice(index, 1);
  }

  appendSectionAlternativeToSection(targetSectionId) {
    // get the targetSection by it's id
    const targetSection = this.getSectionById(targetSectionId);
    if (!targetSection) {
      return
    }
    // get the next available sectionAlternativeId
    let nextSectionAlternativeId = this.workspace.template.next_section_alternative_id++;
    // build the basic sectionAlternativeObject
    let sectionAlternativeObject = {};
    sectionAlternativeObject.id = nextSectionAlternativeId;
    sectionAlternativeObject.name = 'Alternative ' + nextSectionAlternativeId;
    sectionAlternativeObject.selectors = [];
    sectionAlternativeObject.text_alternatives = [];
    sectionAlternativeObject.text_alternatives.push({id: this.workspace.template.next_text_alternative_id++ , text: 'Text'})
    // push the sectionAlternativeObject into the section_alternatives
    targetSection.section_alternatives.push(sectionAlternativeObject);
    this.renderTemplate();
  }

  changeSectionAlternativeOrder(sectionId, dragging, draggedOver) {
    const draggingContent = this.workspace.template.sections[sectionId].section_alternatives[dragging];
    this.workspace.template.sections[sectionId].section_alternatives.splice(dragging, 1);
    this.workspace.template.sections[sectionId].section_alternatives.splice(draggedOver, 0, draggingContent);
    this.renderTemplate();
  }

  /**
   * TEXT ALTERNATIVES --- END
   */

  signalExists(signal, searchArray) {
    return searchArray.includes(signal) || searchArray.includes(`not.${signal}`)
  }

  addSignalToSettings(signal) {
    const selectors = this.workspace.template.settings.selectors;
    if (!this.signalExists(signal, selectors)) {
      selectors.push(signal);
      this.renderTemplate();
    }
  }

  addSignalToLayout(signal, index) {
    const selectors = this.workspace.template.layouts[index].selectors;
    if (!this.signalExists(signal, selectors)) {
      selectors.push(signal);
      this.renderTemplate();
    }
  }

  addSignalToSectionAlternative(signal, sectionId, sectionAlternativeIndex) {
    const selectors = this.workspace.template.sections[sectionId].section_alternatives[sectionAlternativeIndex].selectors;
    if (!this.signalExists(signal, selectors)) {
      selectors.push(signal);
      this.renderTemplate();
    }
  }

  addSignalToSynonymReplacement(signal, targetSynonymId, targetSynonymReplacementId) {
    // get the targetSynonym
    const targetSynonym = this.getSynonymById(targetSynonymId);
    if (!targetSynonym) {
      return;
    }
    // get the targetSynonymReplacement by it's id
    const targetSynonymReplacement = targetSynonym.replacements.find(targetSynonymReplacement => targetSynonymReplacement.id === targetSynonymReplacementId);
    if (!targetSynonymReplacement) {
      return
    }

    if (!this.signalExists(signal, targetSynonymReplacement.selectors)) {
      targetSynonymReplacement.selectors.push(signal);
      this.renderTemplate();
    }
  }

  toggleSignalInLayout(signal, index) {
    let pos = this.workspace.template.layouts[index].selectors.indexOf(signal);
    if (signal.startsWith('not.')) {
      this.workspace.template.layouts[index].selectors[pos] = signal.replace('not.', '');
    } else {
      this.workspace.template.layouts[index].selectors[pos] = 'not.' + signal;
    }
    this.renderTemplate();
  }

  toggleSignalInSectionAlternative(signal, sectionId, sectionAlternativeIndex) {
    let pos = this.workspace.template.sections[sectionId].section_alternatives[sectionAlternativeIndex].selectors.indexOf(signal);
    if (signal.startsWith('not.')) {
      this.workspace.template.sections[sectionId].section_alternatives[sectionAlternativeIndex].selectors[pos] = signal.replace('not.', '');
    } else {
      this.workspace.template.sections[sectionId].section_alternatives[sectionAlternativeIndex].selectors[pos] = 'not.' + signal;
    }
    this.renderTemplate();
  }

  toggleSignalInSynonymReplacement(signal, targetSynonymId, targetSynonymReplacementId) {
    let targetSynonym = this.getSynonymById(targetSynonymId);
    if (!targetSynonym) {
      return;
    }
    // get the targetSynonymReplacement by it's id
    let targetSynonymReplacement = targetSynonym.replacements.find(synonymReplacement => synonymReplacement.id === targetSynonymReplacementId);
    if (!targetSynonymReplacement) {
      return;
    }
    let pos = targetSynonymReplacement.selectors.indexOf(signal);
    if (signal.startsWith('not.')) {
      targetSynonymReplacement.selectors[pos] = signal.replace('not.', '');
    } else {
      targetSynonymReplacement.selectors[pos] = 'not.' + signal;
    }
    this.renderTemplate();
  }

  removeSignalFromLayout(signal, index) {
    let pos = this.workspace.template.layouts[index].selectors.indexOf(signal);
    this.workspace.template.layouts[index].selectors.splice(pos, 1);
    this.renderTemplate();
  }

  removeSignalFromSectionAlternative(signal, sectionId, sectionAlternativeIndex) {
    let pos = this.workspace.template.sections[sectionId].section_alternatives[sectionAlternativeIndex].selectors.indexOf(signal);
    this.workspace.template.sections[sectionId].section_alternatives[sectionAlternativeIndex].selectors.splice(pos, 1);
    this.renderTemplate();
  }

  removeSignalFromSynonymReplacement(signal, targetSynonymId, targetSynonymReplacementId) {
    let targetSynonym = this.getSynonymById(targetSynonymId);
    if (!targetSynonym) {
      return;
    }
    // get the targetSynonymReplacement by it's id
    let targetSynonymReplacement = targetSynonym.replacements.find(synonymReplacement => synonymReplacement.id === targetSynonymReplacementId);
    if (!targetSynonymReplacement) {
      return;
    }
    let pos = targetSynonymReplacement.selectors.indexOf(signal);
    targetSynonymReplacement.selectors.splice(pos, 1);
    this.renderTemplate();
  }

  editTemplateName(value) {
    this.workspace.template.name = value;
    this.renderTemplate();
  }

  editLayoutName(value, index) {
    this.workspace.template.layouts[index].name = value;
    this.renderTemplate();
  }

  editSynonym(value, targetSynonymId) {
    // get the targetSynonym
    let targetSynonym = this.getSynonymById(targetSynonymId);
    if (!targetSynonym) {
      return;
    }
    targetSynonym.key = value.trim();
    this.renderTemplate();
  }

  editSectionName(value, id) {
    this.workspace.template.sections[id].name = value;
    this.renderTemplate();
  }

  editSectionAlternativeName(targetSectionId, targetSectionAlternativeId, value) {
    // get the targetSection by it's id
    const targetSection = this.getSectionById(targetSectionId);
    if (!targetSection) {
      return
    }
    // get the targetSectionAlternative by it's id
    const targetSectionAlternative = targetSection.section_alternatives.find(targetSectionAlternative => targetSectionAlternative.id === targetSectionAlternativeId);
    if (!targetSectionAlternative) {
      return
    }
    targetSectionAlternative.name = value;
    this.renderTemplate();
  }

  deleteLayout(index) {
    this.workspace.template.layouts.splice(index, 1);
    this.renderTemplate();
  }

  deleteLayoutSection(sectionId, targetLayoutId) {
    // get the targetLayout
    const targetLayout = this.getLayoutById(targetLayoutId);
    if (!targetLayout) {
      return;
    }
    let index = targetLayout.section_ids.indexOf(sectionId);
    targetLayout.section_ids.splice(index, 1);
    this.renderTemplate();
  }

  deleteSection(sectionId) {
    delete this.workspace.template.sections[sectionId]
    this.renderTemplate();
  }

  deleteSynonym(synonymId) {
    // get the targetSynonym
    const targetSynonym = this.getSynonymById(synonymId);
    if (!targetSynonym) {
      return;
    }
    let index = this.workspace.template.synonyms.indexOf(targetSynonym)
    this.workspace.template.synonyms.splice(index, 1);
    this.renderTemplate();
  }

  deleteSectionAlternative(targetSectionId, targetSectionAlternativeId) {
    // get the targetSection by it's id
    const targetSection = this.getSectionById(targetSectionId);
    if (!targetSection) {
      return
    }
    // get the targetSectionAlternative by it's id
    const targetSectionAlternative = targetSection.section_alternatives.find(targetSectionAlternative => targetSectionAlternative.id === targetSectionAlternativeId);
    if (!targetSectionAlternative) {
      return
    }
    let targetSectionAlternativeIndex = targetSection.section_alternatives.indexOf(targetSectionAlternative);
    targetSection.section_alternatives.splice(targetSectionAlternativeIndex, 1);
    this.renderTemplate();
  }

  deleteSynonymReplacement(targetSynonymId, targetSynonymReplacementId) {
    // get the targetSynonym
    const targetSynonym = this.getSynonymById(targetSynonymId);
    if (!targetSynonym) {
      return;
    }
    // get the targetSynonymReplacement by it's id
    const targetSynonymReplacement = targetSynonym.replacements.find(targetSynonymReplacement => targetSynonymReplacement.id === targetSynonymReplacementId);
    if (!targetSynonymReplacement) {
      return
    }
    let targetSynonymReplacementIndex = targetSynonym.replacements.indexOf(targetSynonymReplacement);
    targetSynonym.replacements.splice(targetSynonymReplacementIndex, 1);
    this.renderTemplate();
  }

  feedbackError() {
    this.dispatchEvent( {
      type: 'toast-msg',
      message: {
        type: 'warning',
        text: "Feedback kann nicht geöffnet werden",
      },
    });
  }
}